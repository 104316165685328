import * as React from 'react';

import { secondsPerDay, secondsPerHour, secondsPerMinute, secondsPerYear } from '../consts';

interface CountdownClockProps {
    targetDate : Date;
    eventName: string;
    viewType: 'string' | 'bar';
    onTimeUpdate : (lastTime : number, time : number) => void;
}

interface CountdownClockState {
    now : number;
    timeout: NodeJS.Timeout;
}

export default class CountdownClock extends React.Component<CountdownClockProps, CountdownClockState> {
    static defaultProps = {
        viewType: 'bar',
    }

    constructor(props : CountdownClockProps) {
        super(props);

        this.pad = this.pad.bind(this);
        this.cleanStr = this.cleanStr.bind(this);
        this.setTime = this.setTime.bind(this);
        this.tick = this.tick.bind(this);

        this.state = { now : 0, timeout: null };
    }

    pad(str: string, len: number): string {
        return '0'.repeat(Math.max(0, len - str.length)) + str;
    }

    cleanStr(num : number): string {
        return this.pad(num.toFixed(0), 2);
    }

    setTime(now: number): void {
        const lastTime = this.state.now;

        this.setState({ now }, () => {
            this.props.onTimeUpdate(lastTime, now);
        });
    }

    tick(): void {
        this.setState({ timeout: setTimeout(this.tick, 16) });
        const now = (new Date()).getTime() / 1000;
        this.setTime(now);
    }

    componentDidMount(): void {
        this.tick();
    }

    componentWillUnmount(): void {
        if (this.state.timeout !== null) {
            clearTimeout(this.state.timeout);
        }
    }

    render(): JSX.Element {
        const targetTime = this.props.targetDate.getTime() / 1000;
        const now = this.state.now;

        let remaining = Math.floor(targetTime - now);
        const days = Math.floor(remaining / secondsPerDay);
        remaining %= secondsPerDay;
        const hours = Math.floor(remaining / secondsPerHour);
        remaining %= secondsPerHour;
        const minutes = Math.floor(remaining / secondsPerMinute);
        remaining %= secondsPerMinute;
        const seconds = Math.floor(remaining);

        let content: string | JSX.Element;
        switch (this.props.viewType) {
            case 'string':
                content = `${this.cleanStr(days)}:${this.cleanStr(hours)}:${this.cleanStr(minutes)}:${this.cleanStr(seconds)}`;
            case 'bar':
                const daysText = `${days}d`;
                const hoursText = `${hours}h`;
                const minutesText = `${minutes}m`;
                const secondsText = `${seconds}s`;
                const eventNameText = `${this.props.eventName}`;

                const past = targetTime < now;

                const widthPercent = Math.min((1 - (targetTime - now) / secondsPerYear) * 100, 100);
                content = (
                    <div className={`outer-clock-bar${past ? ' past' : ''}`}>
                        <div className={`inner-clock-bar${past ? ' past' : ''}`} style={{ width: `${widthPercent}%` }}>
                            {!past &&
                                <>
                                    <div className="inner-clock-bar-text">{daysText}</div>
                                    <div className="inner-clock-bar-text">{hoursText}</div>
                                    <div className="inner-clock-bar-text">{minutesText}</div>
                                    {/* <div className="inner-clock-bar-text">{secondsText}</div> */}
                                    <div className="inner-clock-bar-divider">-</div>
                                </>}
                            <div className="inner-clock-bar-text">{eventNameText}</div>
                        </div>
                        <div className="outer-clock-bar-text">{daysText}</div>
                        <div className="outer-clock-bar-text">{hoursText}</div>
                        <div className="outer-clock-bar-text">{minutesText}</div>
                        {/* <div className="outer-clock-bar-text">{secondsText}</div> */}
                        <div className="outer-clock-bar-divider">-</div>
                        <div className="outer-clock-bar-text">{eventNameText}</div>
                    </div>
                );
        }

        return (
            <div className="countdown-clock">
                {content}
            </div>
        );
    }
}
